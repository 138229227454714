import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Alert, Input, SelectPicker, Form, Schema} from 'rsuite'
import styled, {css} from 'styled-components'
import Button from 'rsuite/es/Button'
import Icon from 'rsuite/es/Icon'
import TableClientSort from '../../../components/Table/TableClientSort'
import {HAWALA_PAYMENT_MININUM, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from '../../../const'
import {servicePick, compareTwoArrays} from '../../../utils'

const {NumberType} = Schema.Types;

const paymentModels = {};

export default ({
    data = [],
    service,
    loading = false,
    filter = {},
    height = null,
    setSelected,
    currencyList,
    paymentMethodList,
    clearSelectedData,
    setSelectedCurrency,
    setClearSelectedData,
    ...props
}) => {
    let formRefMap = new Map();
    let tableRef = null;

    const [hawalaPaymentList, onChangeHawalaPaymentList] = useState(data);
    const [selectedData, setSelectedData] = useState(new Map());
    const [availablePayment, onChangeAvailablePayment] = useState(new Map());
    const [selectedItems, setSelectedItems] = useState(new Map());
    const [paymentValue, onChangePaymentValue] = useState(new Map());
    const [openEditAvailable, setOpenEditAvailable] = useState(new Set());
    const [formErrors, updateFormErrors] = useState({});

    useEffect(() => {
        if (!compareTwoArrays(data, hawalaPaymentList)) {
            onChangeHawalaPaymentList(data);
        }
    }, [data]);

    useEffect(() => {
        if (clearSelectedData) {
            setSelectedData(new Map());
            onChangeAvailablePayment(new Map());
            setSelectedItems(new Map());
            onChangePaymentValue(new Map());
            setOpenEditAvailable(new Set());
            updateFormErrors({});
            setClearSelectedData(false);
        }
    }, [clearSelectedData]);

    const hawalaMethod = useMemo(() => paymentMethodList && paymentMethodList.length && paymentMethodList.find(item => item.pm_key === 5), [paymentMethodList]);
    const filteredData = useMemo(() => {
        if (!hawalaPaymentList)
            return [];

        const filteredData = hawalaPaymentList.filter(item => {
            if (!item.is_managed)
                return false;

            // if (filter.paid_only && (
            //     (hawalaMethod && (hawalaMethod.limit > item.available_for_payment) ) ||
            //     !item.available_for_payment
            // )) {
            //     return  false
            // }

            if (filter.minimum_payment 
                && (filter.minimum_payment > item.available_for_payment)
                || !item.available_for_payment
            ) {
                return  false
            }

            if (!filter.show_closed_account && !item.active) {
                return  false
            }

            if (filter.account_manager_id && filter.account_manager_id !== item.account_manager_id) {

                return  false
            }

            if (filter.payment_terms_name && filter.payment_terms_name !== item.payment_terms_name) {
                return false;
            }

            return true;
        });

        return filteredData.map(item => item.available_for_payment && item.available_for_payment > 0
            ? (hawalaMethod && (HAWALA_PAYMENT_MININUM > item.available_for_payment))
                ? {...item, preventCheck: true}
                : item
            : {...item, preventCheck: true, disabled: true}
        )
    }, [hawalaPaymentList, filter, hawalaMethod]);

    // clear selections on data update
    useEffect(() => {
        if (tableRef && tableRef.clear) {
            tableRef.clear();
        }
        setClearSelectedData(true);
        if (filteredData.length) {
            const paymentValueCopy = new Map(paymentValue);
            filteredData.forEach(item => {
                paymentModels[item.id] = Schema.Model({
                    "available_for_payment": NumberType('Enter a valid number')
                        .isRequired('Enter a valid number')
                        .max(item.available_for_payment, `Value greater than ready for payment`)
                        .min(HAWALA_PAYMENT_MININUM, `The value is less than minimum ${HAWALA_PAYMENT_MININUM}`)
                })
                if (Object.keys(paymentValue).length < filteredData.length) {
                    const currentValue = availablePayment && availablePayment.has(item.id) ? availablePayment.get(item.id)["available_for_payment"] : item.available_for_payment || 0;
                    paymentValueCopy.set(item.id, currentValue);
                }
            })
            onChangePaymentValue(paymentValueCopy)
        }
    }, [filteredData, filter, tableRef]);

    useEffect(() => {
        const selectedItemsKeys = Array.from(selectedItems.keys());
        const selectedDataKeys = Array.from(selectedData.keys());
        if (selectedDataKeys.length && selectedItemsKeys.some(key => selectedDataKeys.find(sd_key => sd_key === key))) {
            const resultData = Array.from(selectedData.keys()).reduce((items, key) => {
                items[key] = selectedData.get(key);
                return items;
            }, {});

            setSelected(resultData)
        } else {
            setSelected({})
        }
    }, [selectedItems])

    const onCheckPrevented = useCallback(() => {
        Alert.warning("Minimum payment limit is greater than the balance");
    }, []);

    const fillFormsMap = (id, ref) => {
        const formRefMapCopy = new Map(formRefMap);
        formRefMapCopy.set(id, ref);
        formRefMap = formRefMapCopy;
    }

    const selectItem = async (id, value, check) => {
        const selectedDataCopy = new Map(selectedData);
        const selectedItemsCopy = new Map(selectedItems);

        if (check) {
            if (value.has(id)) {
                const selectedValue = value.get(id);
                if (selectedValue.hasOwnProperty("available_for_payment")) {
                    selectedItemsCopy.set(id, {"available_for_payment": selectedValue["available_for_payment"]});
                }
            }

            if (onCheckPrevented) {
                const checkedItem = filteredData.find(item => item.id === id);
                if (checkedItem.preventCheck)
                    onCheckPrevented();
            }
            setShowEditAvailable(true, id);
        } else {
            selectedItemsCopy.delete(id);

            if (selectedDataCopy.has(id))
                selectedDataCopy.delete(id)


            setShowEditAvailable(false, id);
        }

        if (Array.from(selectedDataCopy.keys()).length < Array.from(selectedData.keys()).length)
            setSelectedData(selectedDataCopy);

        setSelectedItems(selectedItemsCopy);

        return selectedItemsCopy;
    };

    const selectAllItems = (checked, selectedList) => {
        const selectedItemsCopy = new Map(selectedItems);
        const selectedDataCopy = new Map(selectedData);
        const paymentValueCopy = new Map(paymentValue);
        const availablePaymentCopy = new Map(availablePayment);

        const openEditAvailableCopy = new Set(openEditAvailable);

        selectedList.forEach(selectedItem => {
            if (checked) {
                const currentAvailablePayment = availablePayment.has(selectedItem.id)
                    && availablePayment.get(selectedItem.id).available_for_payment
                    || selectedItem.available_for_payment
                    || undefined;

                selectedItemsCopy.set(selectedItem.id, {"available_for_payment": currentAvailablePayment});
                selectedDataCopy.set(selectedItem.id, {"available_for_payment": undefined});
            } else {
                selectedItemsCopy.delete(selectedItem.id);

                if (selectedDataCopy.has(selectedItem.id)) {
                    selectedDataCopy.delete(selectedItem.id);

                }
                if (paymentValueCopy.has(selectedItem.id)) {
                    paymentValueCopy.delete(selectedItem.id);
                }
                if (availablePaymentCopy.has(selectedItem.id)) {
                    availablePaymentCopy.delete(selectedItem.id);
                }

                openEditAvailableCopy.delete(selectedItem.id);
            }
        });

        Array.from(selectedItemsCopy.keys()).forEach(key => {
            const formValid = formErrors.hasOwnProperty(key) && !formErrors[key].hasError;

            if (formValid) {
                const currentValue = availablePaymentCopy.has(key) && Object.keys(availablePaymentCopy.get(key)).length
                && availablePaymentCopy.get(key)["available_for_payment"]
                    ? parseFloat(availablePaymentCopy.get(key)["available_for_payment"]) : undefined;
                selectedDataCopy.set(key,
                    {"available_for_payment": currentValue}
                );
            }

        });

        if (Array.from(availablePaymentCopy.keys()).length < Array.from(availablePayment.keys()).length)
            onChangeAvailablePayment(availablePaymentCopy);

        setSelectedData(selectedDataCopy);

        setSelectedItems(selectedItemsCopy);
        onChangePaymentValue(paymentValueCopy);
        setOpenEditAvailable(openEditAvailableCopy);
    };

    const getItemId = (row) => {
        return row['id'];
    };

    const fillSelectedItems = (selectedList) => {
        const selectedItemsCopy = new Map(selectedItems);

        selectedList.forEach(selectedItem => {
            selectedItemsCopy.set(selectedItem.id, {})
        });

        setSelectedItems(selectedItemsCopy);
    };

    const checkMapValueExits = (id, map) => {
        return map && map.has(id) && map.get(id) && Object.keys(map.get(id)).length;
    };

    const setAvailablePayment = async (id, value, check = null) => {
        const availablePaymentCopy = new Map(availablePayment);
        const selectedItemsCopy = new Map(selectedItems);
        const paymentValueCopy = new Map(paymentValue);
        const selectedDataCopy = new Map(selectedData);

        const formError = Object.keys(formErrors).length && formErrors.hasOwnProperty(id) ? formErrors[id]["available_for_payment"] : null;

        const newAvailableData = {"available_for_payment": value};

        if (check === true) {
            availablePaymentCopy.set(id, newAvailableData);
            paymentValueCopy.set(id, value);
            selectedDataCopy.set(id, {});
        } else if (check === false) {
            if (selectedDataCopy.has(id))
                selectedDataCopy.delete(id);

            paymentValueCopy.delete(id);
            availablePaymentCopy.delete(id);
        }

        const formValid = formErrors.hasOwnProperty(id) && !formErrors[id].hasError;
        if (formValid) {
            const currentValue = paymentValueCopy.has(id) && paymentValueCopy.get(id)
                ? parseFloat(paymentValueCopy.get(id)) : undefined;
            selectedDataCopy.set(id, {"available_for_payment": currentValue});
        }

        if (!formError || formError && !formError.hasError)
            setSelectedData(selectedDataCopy);

        setSelectedItems(selectedItemsCopy);
        onChangeAvailablePayment(availablePaymentCopy);
        onChangePaymentValue(paymentValueCopy);
        setShowEditAvailable(false, id);
        return availablePaymentCopy;
    }

    const setDefaultPayment = (id, row) => {
        const availablePaymentCopy = new Map(availablePayment);
        const defaultValue = parseFloat(row.available_for_payment);
        const defaultData = {"available_for_payment": defaultValue};

        const formModel = Object.keys(paymentModels).length && paymentModels.hasOwnProperty(id) ? paymentModels[id] : null;

        let newFormError = formModel.check({"available_for_payment": defaultValue})
        newFormError = newFormError && newFormError.hasOwnProperty("available_for_payment") ? newFormError.available_for_payment : {};
        updateFormErrors({...formErrors, [id]: newFormError})
        availablePaymentCopy.set(id, defaultData);
        onChangeAvailablePayment(availablePaymentCopy);
        const paymentValueCopy = new Map(paymentValue);
        paymentValueCopy.set(id, row.available_for_payment);
        onChangePaymentValue(paymentValueCopy);
    }

    const setShowEditAvailable = (flag, id) => {
        const openEditAvailableCopy = new Set(openEditAvailable);
        if (flag)
            openEditAvailableCopy.add(id);
        else if (!flag && openEditAvailableCopy.has(id))
            openEditAvailableCopy.delete(id);

        setOpenEditAvailable(openEditAvailableCopy);
    };

    const columns = [
        {
            label: 'Name',
            dataKey: 'name',
            value: ((rowData) => (
                <span className="tableFilters__previewText">{rowData["name"]} / {rowData["account_manager_name"] || 'no manager'}</span>
            )),
            flexGrow: 1,
            minWidth: 300,
        },
        {
            label: 'Payment terms',
            dataKey: 'payment_terms_name',
            value: ((rowData) => (
                <span className="tableFilters__previewText">{rowData["payment_terms_name"]}</span>
            )),
            width: 150,
        },
        {
            label: 'Email',
            dataKey: 'name',
            value: ((rowData) => (
                    <span className="tableFilters__previewText">
                        {rowData["contact_email_list"] && rowData["contact_email_list"].length
                            ? rowData["contact_email_list"][0] : ''}
                    </span>)
            ),
            flexGrow: 2
        },
        {
            label: 'Ready for payment',
            name: 'available_for_payment',
            dataKey: 'available_for_payment',
            value: ((rowData) => {
                const resultData = Array.from(selectedData.keys()).reduce((items, key) => {
                    items[key] = selectedData.get(key);
                    return items;
                }, {});
                const defaultDataSelected = Object.keys(resultData).length && Object.keys(resultData).includes(rowData.id) && !resultData[rowData.id]
                    || Object.keys(resultData).length && !Object.keys(resultData).includes(rowData.id)
                    || !Object.keys(resultData).length;

                return <StyledTableText
                    disabled={!defaultDataSelected}
                    className="tableFilters__previewText">{rowData["available_for_payment"]}</StyledTableText>
            }),
            sortable: true,
            flexGrow: 1
        },
        {
            label: 'Available for payment',
            name: 'available_for_payment1',
            dataKey: 'available_for_payment1',
            value: ((rowData) => {
                const openEditAvailableIds = Array.from(openEditAvailable.values());
                const resultData = Array.from(selectedData.keys()).reduce((items, key) => {
                    items[key] = selectedData.get(key);
                    return items;
                }, {});
                const defaultDataSelected = Object.keys(resultData).length
                    && Object.keys(resultData).includes(rowData.id)
                    && resultData[rowData.id] !== undefined;

                if (availablePayment.has(rowData.id) && availablePayment.get(rowData.id) && openEditAvailableIds.includes(rowData.id)) {
                    const formModel = Object.keys(paymentModels).length && paymentModels.hasOwnProperty(rowData.id) ? paymentModels[rowData.id] : null;

                    let newFormError;

                    const currentPaymentValue = paymentValue && paymentValue.has(rowData.id) && paymentValue.get(rowData.id);
                    const currentFormError = Object.keys(formErrors).length && formErrors.hasOwnProperty(rowData.id) && formErrors[rowData.id].hasOwnProperty("hasError") ? formErrors[rowData.id] : {};
                    const formErrorHasError = currentFormError && currentFormError.hasOwnProperty("hasError") ? currentFormError.hasError : false;
                    const formErrorMessage = currentFormError && currentFormError.hasOwnProperty("errorMessage") ? currentFormError.errorMessage : null;

                    return <StyledForm
                        ref={ref => {
                            fillFormsMap(rowData.id, ref)
                        }}
                        className="rs-form-control-wrapper"
                    >
                        <EditField
                            as={Input}
                            value={currentPaymentValue}
                            name="available_for_payment"
                            placeholder="Available for payment"
                            onChange={(value) => {
                                const paymentValueCopy = new Map(paymentValue);
                                paymentValueCopy.set(rowData.id, value);
                                onChangePaymentValue(paymentValueCopy);

                                newFormError = formModel.check({"available_for_payment": value})
                                newFormError = newFormError && newFormError.hasOwnProperty("available_for_payment") ?
                                    newFormError.available_for_payment : {};

                                updateFormErrors({...formErrors, [rowData.id]: newFormError})
                            }}
                        />
                        {formErrorHasError ? <EditFieldError text={formErrorMessage}/> : null}
                        <Button
                            color="green"
                            disabled={formErrorHasError}
                            className="edit_field_button submit"
                            onClick={() => {
                                const currentValue = paymentValue && paymentValue.has(rowData.id)
                                    && paymentValue.get(rowData.id) || rowData.available_for_payment || 0;
                                setAvailablePayment(rowData.id, currentValue, true);
                                setShowEditAvailable(false, rowData.id);

                                const formValid = formErrors.hasOwnProperty(rowData.id) && !formErrors[rowData.id].hasError;
                                if (!formValid) {
                                    const defaultPayment = filteredData.length && filteredData.find(item => item.id === rowData.id);
                                    setDefaultPayment(rowData.id, defaultPayment);
                                }

                                if (currentValue < hawalaMethod.limit) {
                                    Alert.warning(`Available for payment is less than the minimum payment limit (${hawalaMethod.limit})`)
                                }
                            }}
                        >
                            <Icon icon="check"/>
                        </Button>
                        <Button
                            color="red"
                            className="edit_field_button cancel"
                            onClick={() => {
                                setDefaultPayment(rowData.id, rowData)
                            }}
                        >
                            <Icon icon="close"/>
                        </Button>
                    </StyledForm>
                }

                return <StyledConfirmedPayment>
                    <StyledTableText
                        disabled={!defaultDataSelected}
                        className="tableFilters__previewText">{Array.from(paymentValue.keys()).length &&
                    paymentValue.has(rowData.id) && paymentValue.get(rowData.id) || rowData["available_for_payment"]}
                    </StyledTableText>
                    {checkMapValueExits(getItemId(rowData), selectedItems) && <StyledReturnButton
                        appearance="primary"
                        onClick={() => {
                            setDefaultPayment(rowData.id, rowData);
                            setShowEditAvailable(true, rowData.id);
                        }}
                    >
                        <Icon icon="pencil"/>
                    </StyledReturnButton>}
                </StyledConfirmedPayment>
            }),
            width: 220,
            flexGrow: 1
        },
        {
            label: 'Currency',
            dataKey: 'currency',
            value: ((rowData) => (
                <span className="tableFilters__previewText">{rowData["currency"]}</span>
            )),
            width: 100,
            flexGrow: 1
        }
    ];
    return (
        <StyledTableClientSort
            ref={ref => tableRef = ref}
            shouldUpdateScroll={true}
            extraHeight={height || 700}
            data={filteredData}
            loading={loading}
            virtualized={true}
            rowHeight={36}
            defaultSortColumn="available_for_payment"
            {...{
                columns,
                onCheckPrevented,
                ...props
            }}
            isselected
            partialSelectedAll
            clearSelected={clearSelectedData}
            setSelected={(selected) => {
                if (selected && Object.keys(selected).length) {
                    const selectedList = selected.list && filteredData.filter(item => selected.list.includes(item.id));
                    selected.all = selectedList.length === filteredData.length && selectedList.length > 1 || selected.list && !selected.list.length;

                    fillSelectedItems(selectedList);

                    const selectedCurrencyObject = selectedList.reduce((result, item) => {
                        const curKey = currencyList.find(currency => currency.name === item.currency)?.cur_key;

                        result[item.id] = curKey || servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY);

                        return result;
                    }, {});

                    setSelectedCurrency(selectedCurrencyObject);

                    const uncheckedListToDelete = filteredData.filter(item => {
                        return checkMapValueExits(item.id, selectedItems) && !selectedList.map(selectedItem => selectedItem.id).includes(item.id)
                    });
                    if (uncheckedListToDelete.length) {
                        selectAllItems(false, uncheckedListToDelete);
                    } else if (selectedList.length) {
                        selectAllItems(true, selectedList)
                    }
                }
            }}
        />
    )
};

const EditFieldError = ({text}) => (
    <ErrorMessageWrapper className="rs-error-message-wrapper rs-form-control-message-wrapper rs-error-message-placement-top-end">
        <span className="rs-error-message rs-error-message-show">
            <span className="rs-error-message-inner">{text}</span>
        </span>
    </ErrorMessageWrapper>
);

const StyledTableClientSort = styled(TableClientSort)`
    .rs-table-cell-content {
        padding: 0 0 0 10px !important;
    }
`;

const StyledConfirmedPayment = styled.div`
    display: flex;
    align-items: center;
`;

const ErrorMessageWrapper = styled.div`
    position: relative !important;
    margin-top: -10px;
    
    .rs-error-message {
        font-size: 9px !important;
        padding: 0 1px 1px 1px !important;
    }
`;

const EditField = styled(SelectPicker).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
}))`
  width: 130px !important;
`;

const StyledForm = styled(Form)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    right: 0;
    position: absolute;
    
    .edit_field_button {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        padding: 0;
    }
`;
const StyledReturnButton = styled(Button)`
    position: absolute !important;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 5px;
    &.rs-btn {
        padding: 0;
    }
`;

const StyledTableText = styled.span`
   ${props =>
    props.disabled && css`
        color: var(--color-disabled);
    `}
`;


