import React from "react";
import {Form, FormControl, InputNumber, Icon, InputGroup, ControlLabel, FormGroup} from 'rsuite';
import CheckboxControl from "../../../components/Form/CheckboxControl";
import styled from 'styled-components';
import SelectPicker from 'rsuite/es/SelectPicker';
import CustomField from '../../NewAccount/CustomField';
import {debounce} from "../../../utils";


export default ({
    filters,
    account_manager_list,
    paymentTermsList,
    onChangeFilters,
}) => {

    return (
        <StyledForm
            formDefaultValue={filters}
            onChange={debounce((value) => {
                onChangeFilters(value);
            }, 500)}
        >
            <StyledFormControl
                accepter={SelectPicker}
                placeholder="Account manager"
                data={account_manager_list}
                labelKey="name"
                valueKey="id"
                name="account_manager_id"
                label="Account manager"
                searchable={false}
                errorPlacement="topRight"
            />

            <StyledFormControl
                accepter={SelectPicker}
                data={paymentTermsList}
                labelKey="name"
                valueKey="name"
                style={{width: 224}}
                placeholder="Payment term"
                name="payment_terms_name"
                searchable={false}
                errorPlacement="topRight"
            />
            <StyledFormGroup>
                <ControlLabel>Minimal payment amount</ControlLabel>
                <StyledMinimumPaymentField
                    accepter={InputNumber}
                    name="minimum_payment"
                    min={0}
                    max={100000}
                    step={0.1}
                    onChange={(value) => {
                        localStorage.setItem("PAYMENT_MANAGMENT_HAWALA_FILTER_MINIMUM_PAYMENT", value);
                    }}
                    errorPlacement="topRight"
                    placeholder="Payment"
                />
            </StyledFormGroup>
            <CheckboxControl
                name="show_closed_account"
                defaultChecked={false}
            >
                Show closed accounts
            </CheckboxControl>
        </StyledForm>
    )
}
const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }
    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;
const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 200px;
        max-width: 220px;
        overflow: hidden;
    }
`;

const StyledFormGroup = styled(FormGroup)`
    && {
        display: flex;
        align-items: center;

        .rs-control-label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
`;

const StyledMinimumPaymentField = styled(FormControl)`
    && {
        min-width: 80px;
        max-width: 100px;
        overflow: hidden;
    }
`;